import logo from '../logos/blackWB.png';

const Privacy = () => {
  return (
    <div className="container w-75" style={{ fontFamily: 'Alata, sans-serif' }}>
      <div className="w-25 mt-5">
        <img
              alt=""
              src={logo}
              aly="logo"
              max-width="210"
              height="60"
              fluid="false"
              className="d-inline-block align-top"
            />
      </div >
      <h1 style={{ fontFamily: 'Alata, sans-serif' }} className="pt-5">PRIVACY POLICY</h1>
      <p>
        <strong>Last updated September 2024</strong>
      </p>

      <p>
        GoStatim is the Trade Name of RealiVobis LTD (“the Company”,
        “RealiVobis”, “we”). RealiVobis wishes to inform its clients and other
        data subjects (i.e. guarantors) (“you”) about how the Company processes
        their personal data as well as of their data subject rights in
        accordance with Data Protection Law, including but not limited to the
        Cyprus Data Protection Law 125(I)2018, the European General Data
        Protection Regulation 2016/679 (“GDPR”) and the Cyprus legislation
        governing the electronic communications and postal services N.112 (I)
        2004.{" "}
      </p>

      <p>
        Please take the time to read this Privacy Policy. At RealiVobis,
        accessible from{" "}
        <a href="https://www.realivobis.com">https://www.realivobis.com</a>, one
        of our main priorities is the privacy of our visitors. This Privacy
        Policy document contains types of information that is collected and
        recorded by RealiVobis and how we use it.
      </p>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>Definitions:</h2>
      <ol>
        <li>
          The terms “personal data”, “data” and “personal information” refer to
          any information relating to you that identifies or may identify you,
          such as your name or contact details.
        </li>
        <li>
          The term “processing” is used to collectively refer to actions such as
          the collection, retention, use, disclosure, transfer, deletion, or
          destruction of personal data.
        </li>
      </ol>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>WHO WE ARE</h2>
      <p>
        RealiVobis is a company established in accordance with the laws and
        regulations of the Republic of Cyprus. Our head offices and contact
        details:
      </p>
      <ul>
        <li>
          <strong>Company Name:</strong> RealiVobis LTD
        </li>
        <li>
          <strong>Address:</strong> RealiVobis LTD
        </li>
        <li>
          <strong>Email:</strong>{" "}
          <a href="mailto:info@realivobis.com">info@realivobis.com</a>
        </li>
        <li>
          <strong>Company Registration number:</strong> HE426863
        </li>
      </ul>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }} >WHO IS RESPONSIBLE FOR DATA PROCESSING?</h2>
      <p>
        RealiVobis is the data controller of your personal data, meaning that we
        are responsible for determining the purpose and means of processing as
        described in this Privacy Policy.
      </p>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>HOW THE COMPANY COLLECTS AND PROCESSES YOUR PERSONAL DATA?</h2>
      <ul>
        <li>
          <strong>
            Information you or your representatives or third parties provide:
          </strong>{" "}
          The Company collects information about you via the online application
          forms and/or other relevant forms and/or agreements.
        </li>
        <li>
          <strong>Information processed by third-party entities:</strong>{" "}
          Entities providing services and products for Know-Your-Customer (KYC)
          and due diligence purposes, etc.
        </li>
      </ul>
      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>WHAT TYPES OF PERSONAL DATA DOES THE COMPANY COLLECT AND PROCESS?</h2>
      <p>
        The personal data that each registered user must provide to submit
        and/or complete any order through the app are as follows:
      </p>
      <ul>
        <li>Full name</li>
        <li>Postal address</li>
        <li>Contact Phone</li>
        <li>E-mail</li>
        <li>Date of birth</li>
        <li>Recent valid passport or ID photo (both sides of ID)</li>
        <li>
          Take a photo of a face (via webcam or mobile phone camera (selfie))
        </li>
        <li>IBAN number</li>
        <li>Copy of recent pay slip*</li>
        <li>
          Copy of recent proof of address (e.g., water or electricity bill
          showing the user's name and address in full) *
        </li>
      </ul>
      <p>
        * All documents must be dated within the last six months from the date
        of account creation on the app.
      </p>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>LEGAL BASIS AND PURPOSES FOR PROCESSING YOUR PERSONAL DATA</h2>
      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >How we use:</h3>
      <h4 style={{ fontFamily: 'Alata, sans-serif' }} >A) Your name and contact details.</h4>
      <p>
        (a) We need your name and contact details in order to perform our
        contractual obligations:
      </p>
      <ul>
        <li>Identification and application evaluation purposes.</li>
        <li>Carrying out the provision of the Company’s services.</li>
        <li>Passing information to third-party partners for services.</li>
        <li>Performing a contract with you.</li>
        <li>Fraud detection and prevention.</li>
        <li>Keeping you informed about products and services.</li>
      </ul>

      <h4 style={{ fontFamily: 'Alata, sans-serif' }} >(b) For safeguarding our legitimate interests:</h4>
      <ul>
        <li>Verifying your identity to protect against fraud.</li>
        <li>Pursuing claims in judicial and regulatory proceedings.</li>
        <li>Ensuring the best quality and security of operations.</li>
        <li>Protecting Intellectual Property rights.</li>
      </ul>

      <h4 style={{ fontFamily: 'Alata, sans-serif' }} >B) Your payment information, why we need it and how we use it:</h4>
      <ul>
        <li>
          To perform our contract with you, receive payments, and provide
          refunds.
        </li>
        <li>Fraud detection and prevention.</li>
      </ul>
      <p>
        <strong>
          We do not keep records of your card security code (CVV) or any other
          sensitive card information.
        </strong>
      </p>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>DISCLOSURE / SHARING OF PERSONAL DATA</h2>
      <p>
        We may appoint subcontractors who process personal data on our behalf
        (“data processors”), including:
      </p>
      <ul>
        <li>Third-party companies for service provision.</li>
        <li>Marketing agencies and website hosts.</li>
        <li>Subsidiary companies.</li>
        <li>Law enforcement and credit reference agencies.</li>
      </ul>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>ONLINE ADVERTISEMENTS</h2>
      <p>
        To keep clients informed, we engage in online advertising using cookies,
        web beacons, ad tags, and pixels.
      </p>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>COOKIES POLICY</h2>
      <p>
        Cookies are used to improve user experience by storing visitor data. By
        accessing our website, you agree to the use of cookies.
      </p>
      <p>
        The content of this Cookies Policy may change at any time. Please check
        frequently for updates.
      </p>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>Which Cookies do we use and why?</h2>
      <ul>
        <li>
          <strong>Strictly Necessary Cookies:</strong> Required to carry out
          essential functions, such as accessing and browsing the
          Website/application, registration forms, shopping cart, homepage, and
          wish list.
        </li>
        <li>
          <strong>Performance and Analytics Cookies</strong>
        </li>
        <li>
          <strong>Targeting/Advertising Cookies</strong>
        </li>
      </ul>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>How to manage and disable Cookies?</h2>
      <p>
        Your cookie preferences can always be amended, changed, or disabled,
        aside from the Strictly Necessary Cookies, through the browser settings.
      </p>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>SECURITY AND CONFIDENTIALITY</h2>
      <p>
        Your personal data are processed only by authorized personnel and
        safeguarded with technical and organizational measures to ensure
        confidentiality, integrity, and availability.
      </p>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>WHAT ARE YOUR RIGHTS FOR THE PROTECTION OF YOUR DATA?</h2>
      <ul>
        <li>
          <strong>Right to be informed:</strong> Understanding how we use your
          data.
        </li>
        <li>
          <strong>Right of access:</strong> Awareness of what data we maintain
          and process.
        </li>
        <li>
          <strong>Right of rectification:</strong> Request correction of
          inaccurate data.
        </li>
        <li>
          <strong>Right to restrict processing:</strong> Limit processing under
          specific conditions.
        </li>
        <li>
          <strong>Right to object:</strong> Object to data processing under
          legitimate interests.
        </li>
        <li>
          <strong>Right to be forgotten:</strong> Request deletion of personal
          data.
        </li>
        <li>
          <strong>
            Right in relation to automated decision making and profiling.
          </strong>
        </li>
        <li>
          <strong>Right to withdraw consent:</strong> Withdraw consent at any
          time.
        </li>
      </ul>
      <p>
        If you want to exercise any of these rights, contact us at{" "}
        <a href="mailto:info@gostatim.com">info@gostatim.com</a>.
      </p>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>CHANGES TO THIS PRIVACY POLICY</h2>
      <p>
        The Company may modify this Privacy Policy, and users will be notified
        three (3) days before the effective date. Continued use of the
        Website/application implies acceptance.
      </p>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>Retainment of your data</h2>
      <p>
        We retain data as necessary to meet legal and regulatory requirements,
        prevent fraud, and enforce terms and conditions.
      </p>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>Third-Party Privacy Policies</h2>
      <p>
        The Company does not apply to other advertisers or websites. Consult
        their respective Privacy Policies for more information.
      </p>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>Children’s Information</h2>
      <p>
        We do not knowingly collect Personal Identifiable Information from
        children under 14. If such data is provided, contact us immediately for
        removal.
      </p>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>Online Privacy Policy Only</h2>
      <p>
        Applies only to online activities and not to information collected
        offline or through other channels.
      </p>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }}>Consent</h2>
      <p>
        By using our website, you consent to our Privacy Policy and its terms.
      </p>
    </div>
  );
};

export default Privacy;
