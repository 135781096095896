import React, { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import emailjs from '@emailjs/browser';
import logo from '../logos/blackWB.png';
import logo2 from '../logos/blackW.png';
import { Link } from 'react-scroll'
//import Switch from "react-switch";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import { Col, Row,Navbar, Nav, Form } from 'react-bootstrap';
import Translator from '../components/data.json';


const Unsubscribe = () => {
  //Tranlation
  const [language, setLanguage] = useState('en');
  const [content, setContent] = useState({});
  const form = useRef();
  const [successMessage, setSuccessMessage] = useState("");
  const sendEmail = (e) => {
    emailjs.sendForm('service_asfzmv5', 'template_lpkwy5w', form.current, 'PGDmygcUyzyjn5RzD')
      .then((result) => {
        console.log(result.text);
        setSuccessMessage(" ✅ Thank you for contacting us! We’ve received your message and will get back to you as soon as possible.");
      }, (error) => {
        console.log(error.text);
        setSuccessMessage(" ⛔️ There has been an error sending the message, try again.");
      });
  };

   //Dark mode
    const [check, setCheck] = useState(false);
  
    const [theme, setTheme] = useState(
      localStorage.getItem('theme') || 'dark'
    );

  const toggleTheme = () => {
    if (theme === 'dark') {
      setTheme('light');
    } else {
      setTheme('dark');
    }
    setCheck(!check);
  };
  useEffect(() => {
    if (language === 'en') {
      setContent(Translator.en)
      setFont("font-en")
    } else if (language === 'gr') {
      setContent(Translator.gr)
      setFont("font-gr")
    }
    console.log(font)
  })

  //Fonts
  const [font, setFont] = useState('');

  const changeLanguage = (e) => {
    setLanguage(e);
    if (e === 'en') {
      setFont("font-en")
    } else {
      setFont("font-gr")
    }
  }
  //Animation
    const control = useAnimation();
    const [ref, inView] = useInView()
    
    const boxVariant = {
      visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
      hidden: { opacity: 0, scale: 0 }
    };
  //Scroll to top
    const [showScroll, setShowScroll] = useState(false)
  
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true)
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false)
      }
    };
  
    const scrollTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  
    window.addEventListener('scroll', checkScrollTop)
  
    const [show, setShow] = useState(false);
    
  return (
    <div className="container w-50 pt-5" style={{ fontFamily: 'Alata, sans-serif' }}>
      <div className={`${theme}`}>
      <Navbar fixed="top" bg={`${theme}`} expand="lg" id="mainNav">
        <div className="container px-5">
          <Navbar.Brand>
            <img
              alt=""
              src={theme === 'dark' ? logo : logo2}
              aly="logo"
              max-width="210"
              height="60"
              fluid="false"
              className="d-inline-block align-top"
              onClick={scrollTop}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
            </Nav>
            <Nav className='justify-content-end' navbarScroll>  
              <Nav.Item>
                <Link to="join" className={`nav-link ${font} mr-3`} smooth={true}></Link>
              </Nav.Item>
              <Nav.Item>
                <select className={`${theme} rounded-pill mt-2 dark px-2 ${font}`} value={language} onChange={(e) => changeLanguage(e.target.value)} title="Language">
                  <option className={`${font}`} value="en">English</option>
                  <option className={`${font}`} value="gr">Ελληνικά</option>
                </select>
              </Nav.Item>
              <Nav.Item>
                <Link to="join" className={`nav-link ${font} mr-3`} smooth={true}></Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
        </div>
     
    <Formik
      initialValues={{ email: '', name: '', surn: '', tel: '', question: '', message: '' }}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }
        if (!values.name) {
          errors.name = 'Required';
        }
        if (!values.surn) {
          errors.surn = 'Required';
        }
        if (!values.tel) {
          errors.tel = 'Required';
        } else if (!/^[0-9]{8,15}$/.test(values.tel)) {
          errors.tel = 'Invalid tel number';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
        }, 400);
        sendEmail(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        
        <> 
        
         <h3 className="text-center pt-5" style={{marginTop:"30px", fontFamily: 'Alata, sans-serif' }} >{content.titleDeletion}</h3>
         <p className="text-center">{content.subtitledeletion}</p>
         <hr style={{ background: "#ced4da", height: "2px", border: "none", flex: 1 }} />
          <div className='container'>
            <Form ref={form} id="contactForm" className="form-group required" onSubmit={handleSubmit}>
              <Row className="container">
                <Form.Group as={Col} >
                  <Form.Label value="name">{content.Contact1}</Form.Label>
                  <Form.Control
                    style={{ border: errors.name && touched.name ? '1px solid red' : '1px solid #ced4da' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    name="name"
                    id="name"
                    type="text"
                    placeholder={content.Contact1}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Label value="id_artists">{content.Contact2}</Form.Label>
                  <Form.Control
                    style={{ border: errors.surn && touched.surn ? '1px solid red' : '1px solid #ced4da' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.surn}
                    name="surn"
                    id="id_artists"
                    placeholder={content.Contact2}
                  />
                </Form.Group>
              </Row>
  
              <Row className="container">
                <Form.Group className="mb-3">
                  <Form.Label value="artists">{content.Contact3}</Form.Label>
                  <Form.Control
                    style={{ border: errors.email && touched.email ? '1px solid red' : '1px solid #ced4da' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    name="email"
                    id="artists"
                    placeholder={content.Contact3}
                  />
                </Form.Group>
              </Row>
  
              <Row className="container">
                <Form.Group>
                  <Form.Label value="popularity">{content.Contact4}</Form.Label>
                  <Form.Control
                    style={{ border: errors.tel && touched.tel ? '1px solid red' : '1px solid #ced4da' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tel}
                    name="tel"
                    id="popularity"
                    placeholder={content.Contact4}
                  />
                </Form.Group>
              </Row>
  
              <Row className="container">
                <Form.Group className="mb-0">
                  <Form.Label value="artists"></Form.Label>
                </Form.Group>
              </Row>
  
              <Row className="container">
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>{content.Contact13}</Form.Label>
                  <Form.Control name="message" as="textarea" rows={3} placeholder={content.Contact14} />
                </Form.Group>
              </Row>
            </Form>

          </div>
  
          {/* Mensaje de éxito */}
          {successMessage && <p className="text-success">{successMessage}</p>}
          <button className=" btn-primary rounded-pill btn-lg mb-4" type="submit" form="contactForm" disabled={isSubmitting}>
                  {content.Contact9}
          </button>
        </>
        
      )}
    </Formik>
    </div>
  );
};
  

export default Unsubscribe;