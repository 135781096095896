import logo from '../logos/blackWB.png';

const TermAndConditions = () => {
  
  return (
    <div className="container w-75" style={{ fontFamily: 'Alata, sans-serif' }}>
      <div className="w-25 mt-5">
        <img
              alt=""
              src={logo}
              aly="logo"
              width="210"
              height="60"
              fluid="false"
              className="d-inline-block align-top"
            />
      </div>
      <h1 style={{ fontFamily: 'Alata, sans-serif' }} className="pt-5">Terms of Use</h1>
      <p>
        The website <a href="https://www.gostatim.com">www.gostatim.com</a> and
        the app GoStatim are the property of the company RealiVobis LTD.
        Visiting and using this website and app presuppose the unconditional
        acceptance of the following conditions. By continuing to browse the
        content of the website, you accept the terms of use as stated below.
      </p>

      <h2 style={{ fontFamily: 'Alata, sans-serif' }} >
        Terms of Service of RealiVobis LTD (the "Company") Website and the
        GoStatim Application
      </h2>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >General Terms</h3>
      <p>
        The use of the website{" "}
        <a href="https://www.gostatim.com">www.gostatim.com</a> and GoStatim app
        by each user presupposes their unreserved agreement with the following
        terms of use, which apply to all the content included on this website.
        Users should read these terms carefully before using the services of the
        website and app. If they do not agree, they should not use the services
        and their content.
      </p>
      <p>
        All users are requested to check the content of the terms of use for
        possible changes. The continued use of this website and the app even
        after any changes means the user's unreserved acceptance of these terms.
        By using the Services, you accept these Terms and Conditions.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >1. Copyright</h3>
      <p>
        All website and app design, text, graphics, and settings are the
        intellectual property of the Company and are protected according to the
        relevant provisions of Cypriot legislation, European Law, and
        international conventions.
      </p>
      <p>
        All rights reserved. Any text or image bearing the Company's symbols may
        be used with permission from the Company. Reproduction in any form of
        any part of this website's content without the Company's prior written
        consent is not allowed. You shall not use the name, logo, trademarks, or
        tradenames of the Company in any publicity release, promotional
        material, advertising, or marketing without prior written consent.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >2. Personal Data Policy</h3>
      <p>
        The Company is the controller of the user's personal data and controls
        the processing of data on the app.
      </p>

      <h4 style={{ fontFamily: 'Alata, sans-serif' }} >2.1 Purpose of Data Processing</h4>
      <p>
        The primary purpose of processing data is to complete orders,
        communicate with users, send informational messages, confirm identity,
        and evaluate financial transactions.
      </p>

      <h4 style={{ fontFamily: 'Alata, sans-serif' }} >2.2 Communication</h4>
      <p>
        The Company reserves the right to inform users regularly through various
        means unless the user objects.
      </p>

      <h4 style={{ fontFamily: 'Alata, sans-serif' }} >2.3 User Rights</h4>
      <p>
        Users have the right to access, correct, and update their personal data.
        They must notify the Company of any changes.
      </p>

      <h4 style={{ fontFamily: 'Alata, sans-serif' }} >2.4 Responsibility</h4>
      <p>
        Users accept responsibility for activities under their account and must
        maintain password confidentiality. Unauthorized access should be
        reported to <a href="mailto:info@gostatim.com">info@gostatim.com</a>.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >3. Processing and Type of Personal Data – User Rights</h3>
      <h4 style={{ fontFamily: 'Alata, sans-serif' }} >3.1 Data Processing</h4>
      <p>The Company processes user data provided during account creation.</p>

      <h4 style={{ fontFamily: 'Alata, sans-serif' }} >3.2 Additional Information</h4>
      <p>
        The Company may request additional information for registration, which
        will be processed according to these terms.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >4. User Registration on the App</h3>
      <h4 style={{ fontFamily: 'Alata, sans-serif' }} >4.1 Account Creation</h4>
      <p>
        Users must create an account to register on the app. Their personal data
        will be stored until deletion is requested.
      </p>

      <h4 style={{ fontFamily: 'Alata, sans-serif' }} >4.2 Annual Data Confirmation</h4>
      <p>
        The Company will request users to confirm and update their data
        annually. Users can update their data at any time by contacting the
        Company.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >5. Type of Personal Data</h3>
      <p>
        Each registered user must provide the following personal data to submit
        and/or complete any order through the app:
      </p>
      <ul>
        <li>Full name</li>
        <li>Postal address</li>
        <li>Contact phone</li>
        <li>Email</li>
        <li>Date of birth</li>
        <li>Recent valid passport or ID photo (both sides of ID)</li>
        <li>Selfie (via webcam or mobile phone camera)</li>
        <li>IBAN number</li>
        <li>Copy of recent pay slip*</li>
        <li>Copy of recent proof of address (e.g., utility bill)</li>
      </ul>
      <p>
        * All documents must be dated within the last six months from the date
        of account creation on the app.
      </p>

      <h4 style={{ fontFamily: 'Alata, sans-serif' }} >5.2 Processing Consent</h4>
      <p>
        The provision of mandatory personal data and transaction details is
        necessary for contract execution and does not require user consent.
      </p>

      <h4 style={{ fontFamily: 'Alata, sans-serif' }} >5.3 Secure Storage</h4>
      <p>
        Bank account details are stored in the Company's secure storage
        environment.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >6. Data Recipients</h3>
      <p>
        For the data necessary to serve any of the above processing purposes,
        the recipients of the user's data can be:
      </p>
      <ul>
        <li>
          The competent employees and/or third-party service providers that
          cooperate with the Company.
        </li>
        <li>
          The respective cooperating supplier in accordance with the purchase
          agreement.
        </li>
        <li>The person responsible for delivering the order.</li>
      </ul>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >7. Data Subject Rights</h3>
      <p>
        Each registered user, as a data subject, may at any time exercise their
        rights as provided for in the General Regulation on the Protection of
        Personal Data 679/2016 EU and articles 12 to 23 thereof and Cypriot
        legislation, including:
      </p>
      <ul>
        <li>
          The right to information and access to the data processed by the
          Company.
        </li>
        <li>The right to limit the processing of their data.</li>
        <li>
          The right to correct or delete part or all of their personal data
          (right to be forgotten).
        </li>
        <li>
          The right to object, i.e., to raise objections to the processing of
          their personal data.
        </li>
        <li>The right to data portability.</li>
      </ul>
      <p>
        To correct and/or complete personal data or to delete a user, they can
        send an email to{" "}
        <a href="mailto:info@gostatim.com">info@gostatim.com</a>.
      </p>
      <p>
        Users have the right to withdraw their consent at any time for any
        processing of their personal data without affecting the legality of the
        processing before withdrawal.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >8. Acceptable Use</h3>
      <p>You must not use the Services in any way that:</p>
      <ul>
        <li>Violates any Applicable Laws.</li>
        <li>
          Causes, or is likely to cause, any Service interruption, damage, or
          impairment.
        </li>
        <li>
          Encourages, promotes, facilitates, or instructs others to engage in
          illegal activities.
        </li>
        <li>Infringes or violates intellectual property or privacy rights.</li>
        <li>Misrepresents identity or affiliation.</li>
        <li>Harms, disrupts, or diminishes the Services.</li>
        <li>
          Modifies, translates, adapts, or reverse-engineers software used in
          the Services.
        </li>
        <li>
          Causes network attacks, including denial of service (DOS) or spamming.
        </li>
        <li>
          Collects, sells, or processes personal information without explicit
          permission.
        </li>
        <li>Distributes false, misleading, or offensive information.</li>
        <li>Interferes with any other party's ability to use the Services.</li>
        <li>
          Promotes discrimination based on race, sex, religion, nationality, or
          other factors.
        </li>
      </ul>
      <p>
        We reserve the right to refuse access, terminate accounts, or remove
        content if these terms are breached.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >9. Obligation of Transparency</h3>
      <p>
        For any information regarding data processing and protection, users may
        contact the Company at{" "}
        <a href="mailto:info@gostatim.com">info@gostatim.com</a>.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >10. Technical Protection Measures</h3>
      <p>
        The Company implements appropriate technical and organizational measures
        to protect personal data against destruction, loss, or unauthorized
        access.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >11. Modification of Terms and Conditions</h3>
      <p>
        The Company reserves the right to modify any terms, services, and
        policies at any time. Users continuing to use the website and
        application will be deemed to accept and be bound by the changes.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >12. Indemnification</h3>
      <p>
        You agree to indemnify us for all claims brought by a third party
        against us arising out of or in connection with the content and material
        you supply except to the extent that any liability arises from our
        failure to properly remove the content when we are notified of the
        illegal nature of the content arising out of or on the grounds of, or
        originating from, the content that you have communicated to us.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >13. Liability</h3>
      <p>
        13.1. In case of any problems with the website or application, users are
        requested to inform the Company at the following contact details:{" "}
        <a href="mailto:info@gostatim.com">info@gostatim.com</a>.
      </p>
      <p>
        13.2. We will do our utmost best to ensure that availability of the
        Services will be uninterrupted and that transmissions will be
        error-free. However, due to the nature of the internet, this cannot be
        guaranteed. In addition, your access to the Services may also be
        occasionally suspended or restricted to allow for repairs, maintenance,
        or the introduction of new facilities or services.
      </p>
      <p>
        13.3. We are liable for negligent breach of essential obligations.
        Essential obligations are those the breach of which puts at risk the
        achievement of the contractual purpose. However, we are not liable for
        negligent breach of any other obligations.
      </p>
      <p>
        13.4. These limitations do not apply to damages from injury to life,
        body, or health.
      </p>
      <p>
        13.5. If the Company's liability is excluded or limited, such exclusion
        or limitation also applies to its employees, legal representatives, or
        agents.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >14. Violation/Breaches</h3>
      <p>
        We may terminate or suspend your account if we reasonably believe you
        are violating these Terms and Conditions or any applicable laws. We may
        also cooperate with law enforcement and disclose your information if
        required by authorities.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >15. Right of Withdrawal</h3>
      <p>
        If you buy a product or service from a professional trader under EU
        Directive 2011/83, you may have the right to withdraw within 14 days
        under specific conditions.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >16. Payment</h3>
      <p>
        Payments may be processed through a third-party provider. All
        transactions are between you and the Company or Partner. Your payment
        options will be indicated at checkout.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >17. Final Provisions</h3>
      <p>
        17.1. The Company may terminate the agreement with two months’ notice.
        You may terminate your account with one month’s notice via email.
      </p>
      <p>
        17.2. Any disputes shall be resolved by the competent Courts of Cyprus.
      </p>
      <p>
        17.3. If any part of these terms is found invalid or unenforceable, the
        rest will continue to apply.
      </p>

      <h3 style={{ fontFamily: 'Alata, sans-serif' }} >18. More Information</h3>
      <p>
        For further information, you can contact the Company via email at{" "}
        <a href="mailto:info@gostatim.com">info@gostatim.com</a>.
      </p>
    </div>
  );
};

export default TermAndConditions;
