import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Slides from './components/slides';
import Calculator from './components/calculator';
import FAQ from './components/faq';
import Footer from './components/footer';
import Serv from './components/services';
import Join from './components/join';
import { Link } from 'react-scroll'
import pic from './Screenshot_20230207_012614.png';
import MobileStoreButton from 'react-mobile-store-button';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './logos/blackWB.png';
import logo2 from './logos/blackW.png';
import { Col, Button, Row, Form, Nav, Navbar, Modal, NavItem, NavDropdown, Container } from 'react-bootstrap';
import Counter from './components/counter';
import Blog from './components/blog';
import CookieConsent from "react-cookie-consent";
import { AiOutlineMail } from 'react-icons/ai';
import emailjs from '@emailjs/browser';
import { Player } from 'video-react';
import { Formik } from 'formik';
import Translator from './components/data.json';
import Switch from "react-switch";
import termsEn from './pdfs/TermsOfServiceEn.pdf';
import termsGr from './pdfs/TermsOfServiceGr.pdf';
import policy from './pdfs/PrivacyPolicyEn.pdf';
import { BsFillTelephoneFill } from "react-icons/bs"
import Info from './components/info';
// Routes
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Privacy from "./components/privacy";
import TermAndConditions from "./components/termAndConditions";
import Unsubscribe from './pages/unsubscribe';

const App = () => {

  //Tranlation
  const [language, setLanguage] = useState('en');
  const [content, setContent] = useState({});

  useEffect(() => {
    if (language === 'en') {
      setContent(Translator.en)
      setFont("font-en")
    } else if (language === 'gr') {
      setContent(Translator.gr)
      setFont("font-gr")
    }
    console.log(font)
  })

  //Fonts
  const [font, setFont] = useState('');

  const changeLanguage = (e) => {
    setLanguage(e);
    if (e === 'en') {
      setFont("font-en")
    } else {
      setFont("font-gr")
    }

  }

  //Modal
  const form = useRef();

  const [show, setShow] = useState(false);

  const [data, setData] = useState({
    name: "",
    surname: "",
    email: "",
    tel: "",
    question: "",
    message: ""
  });

  const terms = language === 'en' ? termsGr : termsEn ;
  const [successMessage, setSuccessMessage] = useState("");
  const handleClose = () => setShow(false);

  const sendEmail = (e) => {
    emailjs.sendForm('service_asfzmv5', 'template_lpkwy5w', form.current, 'PGDmygcUyzyjn5RzD')
      .then((result) => {
        console.log(result.text);
        setSuccessMessage(" ✅ Thank you for contacting us! We’ve received your message and will get back to you as soon as possible.");
      }, (error) => {
        console.log(error.text);
        setSuccessMessage(" ⛔️ There has been an error sending the message, try again.");
      });
  };

  //Dark mode
  const [check, setCheck] = useState(false);

  const [theme, setTheme] = useState(
    localStorage.getItem('theme') || 'dark'
  );

  const toggleTheme = () => {
    if (theme === 'dark') {
      setTheme('light');
    } else {
      setTheme('dark');
    }
    setCheck(!check);
  };

  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.className = theme;
  }, [theme]);

  //Animation
  const control = useAnimation();
  const [ref, inView] = useInView()

  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 }
  };

  //Scroll to top
  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  window.addEventListener('scroll', checkScrollTop)

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  //Mobile store button
  const iOSUrl = 'https://itunes.apple.com/us/app/all-of-the-lights/id959389722?mt=8';

  return (
      <Router>
      <Routes>
        {/* Página Principal */}
        <Route
          path="/"
          element={
    <div className={`${theme}`}>
      {/* <CookieConsent
        location="bottom"
        buttonText="Okay, I got it"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#E0AA22", fontSize: "15px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <a style={{ fontSize: "10px" }}>Learn more and manage your cookies</a>
      </CookieConsent> */}


      <Navbar fixed="top" bg={`${theme}`} expand="lg" id="mainNav">
        <div className="container px-5">
          <Navbar.Brand>
            <img
              alt=""
              src={theme === 'dark' ? logo : logo2}
              aly="logo"
              max-width="210"
              height="60"
              fluid="false"
              className="d-inline-block align-top"
              onClick={scrollTop}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
            </Nav>
            <Nav className='justify-content-end' navbarScroll>
              <span className={`${font} mt-2`} >{language === 'en' ? 'Dark Mode' : 'Σκοτεινή Λειτουργία'}</span>
              <Nav.Item>
                <Link to="join" className={`nav-link ${font} mr-3`} smooth={true}></Link>
              </Nav.Item>
              <Nav.Item className="mt-1">
                <Switch className="mt-2" height={20} width={45} offColor="#ced4da" onColor="#E0AA22" offHandleColor="#2B373B" onHandleColor="#2B373B"
                  onChange={toggleTheme}
                  checkedIcon uncheckedIcon checked={check} />
              </Nav.Item>
              <Nav.Item>
                <Link to="join" className={`nav-link ${font} mr-3`} smooth={true}></Link>
              </Nav.Item>
              <Nav.Item>
                <select className={`${theme} rounded-pill mt-2 dark px-2 ${font}`} value={language} onChange={(e) => changeLanguage(e.target.value)} title="Language">
                  <option className={`${font}`} value="en">English</option>
                  <option className={`${font}`} value="gr">Ελληνικά</option>
                </select>
              </Nav.Item>
              <Nav.Item>
                <Link to="join" className={`nav-link ${font} mr-3`} smooth={true}></Link>
              </Nav.Item>
              <Nav.Item>
                <button onClick={() => setShow(true)} className="btn-primary rounded-pill px-4 mb-10 mb-lg-0 mt-1" data-bs-toggle="modal">
                  <span className="d-flex align-items-center">
                    <i className="bi-chat-text-fill me-2"></i>
                    <span className={`${font} small`}>{content.Contact}</span>
                  </span>
                </button>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      <header className={`masthead ${theme} `}>
        <div className="container px-5 ">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <div className="mb-5 mb-lg-0 text-center text-lg-start">
                <h3 className="display-4 lh-1 mb-4 font-en">{content.Header1}<br /> {content.Header2}</h3>
                <p className="lead fw-normal  mb-5 font-en">
                  {content.Header3}
                  <br />
                  <br />
                  {content.Header4}
                </p>
                {/* <div className="d-flex flex-column flex-lg-row align-items-center">
                  <MobileStoreButton
                    store="ios"
                    url={iOSUrl}
                    linkProps={{ title: 'iOS Store Button' }}
                    style={{ height: 65, width: 190 }}
                  />
                  <MobileStoreButton
                    store="android"
                    url={iOSUrl}
                    style={{ height: 95, width: 215, marginTop: 5 }}
                    linkProps={{ title: 'Android Store Button' }}
                  />
                </div> */}
              </div>
            </div>
            <div className="col-lg-6">
              <motion.div
                ref={ref}
                variants={boxVariant}
                initial="hidden"
                animate={control}>
                <div className="masthead-device-mockup">
                  <svg className="circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <linearGradient id="circleGradient" gradientTransform="rotate(45)">
                        <stop className="gradient-start-color" offset="0%"></stop>
                        <stop className="gradient-end-color" offset="100%"></stop>
                      </linearGradient>
                    </defs>
                    <circle cx="50" cy="50" r="50"></circle>
                  </svg>
                  <div className="device-wrapper">
                    <div className="device" data-device="iPhoneX" data-orientation="portrait" data-color="black">
                      <div className="screen bg-black">
                        {/* <video autoPlay muted loop max-width="100%" height="100%">
                          <source src="assets/img/slideshow.mp4" type="video/mp4" />
                        </video> */}
                        <img src="assets/img/gpStatim.jpg" alt="Image" max-width="100%" height="100%" />
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </header>
      <Formik
        initialValues={{ email: '', name: '', surn: '', tel: '', question: '', message: '' }}
        validate={values => {
          // console.log(values)
          const errors = {};
          if (!values.email) {
            // console.log('email')
            errors.email = 'Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            // console.log('email2')
            errors.email = 'Invalid email address';
          }
          if (!values.name) {
            // console.log('name')
            errors.name = 'Required';
          }
          if (!values.surn) {
            // console.log('surn')
            errors.surn = 'Required';
          }
          if (!values.tel) {
            errors.tel = 'Required';
          } else if (!/^[0-9]{8,15}$/.test(values.tel)) {
            errors.tel = 'Invalid tel number';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
          }, 400);
          sendEmail(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Modal className="modal fade text-dark" show={show} onHide={() => setShow(false)} tabIndex="-1" aria-labelledby="feedbackModalLabel" aria-hidden="true">
            <Modal.Header className="modal-header contact p-4" closeButton>
              <Modal.Title className={`modal-title font-alt text-white ${font}`}>{content.Contact}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
              <div className='container'>
                <Form ref={form} id="contactForm" className="form-group required" onSubmit={handleSubmit}>
                  <Row className="container">
                    <Form.Group as={Col} >
                      <Form.Label value="name" className={`control-label ${font}`}>{content.Contact1}</Form.Label>
                      <Form.Control
                        style={{ border: errors.name && touched.name ? '1px solid red' : '1px solid #ced4da' }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        name="name" id="name" type="name" placeholder={content.Contact1} required="required" />
                    </Form.Group>

                    <Form.Group className="mb-3" as={Col}>
                      <Form.Label value="id_artists" className={`control-label ${font}`}>{content.Contact2}</Form.Label>
                      <Form.Control
                        style={{ border: errors.surn && touched.surn ? '1px solid red' : '1px solid #ced4da' }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.surn}
                        name="surn" id="id_artists" placeholder={content.Contact2} />
                    </Form.Group>
                  </Row>

                  <Row className="container">
                    <Form.Group className="mb-3">
                      <Form.Label value="artists" className={`control-label ${font}`}>{content.Contact3}</Form.Label>
                      <Form.Control
                        style={{ border: errors.email && touched.email ? '1px solid red' : '1px solid #ced4da' }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        name="email" id="artists" placeholder={content.Contact3} />
                    </Form.Group>
                  </Row>

                  <Row className="container">
                    <Form.Group  >
                      <Form.Label value="popularity" className={`control-label ${font}`}>{content.Contact4}</Form.Label>
                      <Form.Control
                        style={{ border: errors.tel && touched.tel ? '1px solid red' : '1px solid #ced4da' }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tel}
                        name="tel" id="popularity" placeholder={content.Contact4} />
                    </Form.Group>
                  </Row>
                  <Row className="container">
                    <Form.Group className="mb-2">
                      <Form.Label value="artists" ></Form.Label>
                    </Form.Group>
                  </Row>
                  <Container>
                    <Row className="container" >
                      <Form.Select className={`${font}`} name="question" aria-label="Default select example">
                        <option>{content.Contact5}</option>
                        <option value="2" >{content.Contact6}</option>
                        <option value="3" >{content.Contact7}</option>
                      </Form.Select>
                    </Row>
                  </Container>
                  <Row className="container">
                    <Form.Group className={`${font} mb-3`} controlId="exampleForm.ControlTextarea1">
                      <Form.Label></Form.Label>
                      <Form.Control name="message" as="textarea" rows={3} placeholder={content.Contact8} className="control-label" />
                    </Form.Group>
                  </Row>
                </Form>
                <hr
                  style={{
                    background: "#ced4da",
                    height: "2px",
                    border: "none",
                    flex: 1,
                  }}
                />
              </div>
              {successMessage && <p className="text-success">{successMessage}</p>}
            </Modal.Body>
            <div className="container">
              <div className="d-grid">
                <button className=" btn-primary rounded-pill btn-lg mb-4" type="submit" form="contactForm" disabled={isSubmitting}>
                  {content.Contact9}
                </button>
              </div>
              <p className='text-center text-muted'>
                <>
                {content.Contact10}
                {` `}
                <a target='_blank'  href={terms} >{content.Contact11}</a> 
                {` & `}
                <a target='_blank'  href={policy} >{content.Contact12}</a>
                </>
              </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1, backgroundColor: "#ced4da", height: "1px" }} />

                <p style={{ margin: "0 40px" }}>or</p>

                <div style={{ flex: 1, backgroundColor: "#ced4da", height: "1px" }} />
              </div>
              <footer className="bg-white text-center py-5">
                <div className="container px-5">
                  <div className="text-black-50 big">
                    <div className="mb-2">
                      <Row>
                        <Col>
                          <BsFillTelephoneFill size={20}/>
                          <p>+357 96 531373</p>
                        </Col>
                        <Col>
                          <AiOutlineMail size={20}/>
                          <p>Info@gostatim.com</p>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </Modal>
        )}
      </Formik>
      <Serv content={content} font={font} />
      <Slides theme={theme} content={content} font={font} />
      {/* <Calculator content={content} font={font} /> */}
      <Info content={content} font={font} theme={theme} />
      <Blog content={content} font={font}/>
      <FAQ theme={theme} content={content} font={font} />
      {/* <Join font={font} /> */}
      <Footer font={font} language={language} content={content} />
    </div>
   }
   />
   {/* Route Terms And Conditions */}
   <Route path="/termandconditions" element={<TermAndConditions />} />

  {/* Route Privacy*/}
  <Route path="/privacy" element={<Privacy />} />
  {/* Route Unsuscribe */}
  <Route path="/unsubscribe" element={<Unsubscribe />} />
  </Routes>
  </Router>
  );
  
}

export default App;
