import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
//import { Row, Col } from 'react-bootstrap'
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
//import { Typography } from '@mui/material';

const Serv = (props) => {

    const content = props.content;
    const font = props.font;

    const control = useAnimation()
    const [ref, inView] = useInView()

    const boxVariant1 = {
        visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
        hidden: { opacity: 0, scale: 0 }
    };

    const boxVariant2 = {
        visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
        hidden: { opacity: 0, scale: 0 }
    };

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);

    return (
        <section id="serv" className="text-center">
            <Box component="section" py={1}>
                <Container>
                    <Grid
                        container
                        item
                        justifyContent="center"
                        mx="auto"
                        textAlign="center"
                        pb={6}
                    >
                    </Grid>
                    <h2 className={`display-4  ${font}`}>
                                    {content.Service1}
                                </h2>
                    <p className={`lead ${font}`}>
                                    {content.Service2}
                                </p>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                            <motion.div
                                ref={ref}
                                variants={boxVariant1}
                                initial="hidden"
                                animate={control}>
                                <span className="fa-stack fa-2x">
                                    <i className="fas fa-square fa-stack-2x icon-color"></i>
                                    <i className="fas fa-globe fa-stack-1x fa-inverse"></i>
                                </span>
                                <p className='md-1'></p>
                                <h4 className={`${font}`}>{content.Service3}</h4>
                                <p className={`fw-normal  mb-0 ${font}`}>{content.Service4}</p>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <motion.div
                                ref={ref}
                                variants={boxVariant2}
                                initial="hidden"
                                animate={control}>
                                <span className="fa-stack fa-2x">
                                    <i className="fas fa-square fa-stack-2x icon-color2"></i>
                                    <i className="fab fa-cloudversify fa-stack-1x fa-inverse"></i>
                                </span>
                                <p className='md-1 '></p>
                                <h4 className={`${font}`}>{content.Service5}</h4>
                                <p className={`fw-normal  mb-0 ${font}`}>{content.Service6}</p>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <motion.div
                                ref={ref}
                                initial="hidden"
                                animate={control}>

                            </motion.div>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </section>
    )
}

export default Serv;